export const RequestMethod = {
    GET: 'GET',
    PUT: 'PUT',
    POST: 'POST',
    DELETE: 'DELETE',
    OPTIONS: 'OPTIONS',
};

export const HEADERS = { 'Content-Type': 'application/json' };

export const LOGIN_OK = 'ok';
export const LOGIN_DISABLED = 'autologin_disabled';
export const LOGIN_REQUIRED = 'login_required';
export const INTERACTION_REQUIRED = 'interaction_required';
export const LOGGED_OUT = 'logged_out';
export const REFRESH = 'refresh';
export const FORCE_LOAD_USERDATA = 'force_load_userdata';

export const SSO_SESSION_REPLY = 'sso-jreply';
export const SSO_SESSION_STATE = 'sso-state';
export const SSO_SESSION_CURRENT_SCOPES = 'sso-scopes';
export const SSO_SESSION_SESSION_SCOPE = 'sso-session-scope';
export const SSO_SESSION_RETRY_TYPE = 'sso-retry-type';
export const SSO_SESSION_RETRY_COUNTER = 'sso-retry-counter';
export const SSO_SESSION_INTERACTION_REQUIRED = 'sso-interaction-required';

export const SSO_EVENT = 'SSO-stateChanged';

export const DISABLE_ANALYTICS = false;
export const ENABLE_ANALYTICS = true;

export const TAB = '_blank';

export const KEYS = {
    TAB: 'Tab',
    ENTER: 'Enter',
    SPACE: ' ',
    ARROW_DOWN: 'ArrowDown',
    ESCAPE: 'Escape',
    ESC: 'Esc',
};

export const COMMA = ',';

export const ONE_MINUTE_IN_MILLIS = 60 * 1000;
export const ONE_DAY_IN_MILLIS = 24 * 60 * ONE_MINUTE_IN_MILLIS;

export const MARGIN = 5 * ONE_MINUTE_IN_MILLIS;
export const FALLBACK_REFRESH_TIME = 15 * ONE_MINUTE_IN_MILLIS;
export const MAX_ALLOWED_REFRESH_TIME = 20 * ONE_DAY_IN_MILLIS;

export const RETRY_LIMIT = 1;
export const RETRY_TIMEOUT = 3 * 60 * 1000;
export const ID_TOKEN_HINT_PARAM = 'id_token_hint';
export const PROFILE = 'profile';
export const USERDATA = 'userdata';
export const PROFILE_TYPE = { MAIN: 'main', CHILD: 'child', NONE: 'none' };

export const LIVE_INTERACTION = 'liveInteraction';

export const NO_INTERACTION = 'no-interaction';

export const CHILD_LOGIN_CODE = 'childLoginCode';

export const INTENTION = 'intention';
export const LOGIN_INTENTION = 1;
export const LOGOUT_INTENTION = -1;
