import {
    clearChildLoginCode,
    clearLiveInteraction,
    clearSessionStorage,
    enableForceLoadUserData,
    getChildLoginCode,
    getSession,
    getSessionScope,
    setChildLoginCode,
    setLoginIntention,
    setNoInteraction,
} from '../gateway/Storage';
import { getScopesWith } from '../gateway/Cookies';
import { addResumePageQueryParam } from '../util/Util';
import { INTERACTION_REQUIRED, LOGIN_INTENTION, RequestMethod } from '../util/Constants';
import { request } from '../gateway/Request';
import { error } from '../util/Logger';
/**
 * @typedef {import("./Login").cfg} cfg
 */

/**
 *
 * @param {cfg} config
 * @returns {function(string, string, string): void}
 */
export const manualLoginFor = config => (err, customScope, sessionScope) => {
    const scope = getScopesWith([customScope, sessionScope, config.addedScope]);
    let url;
    if (err === INTERACTION_REQUIRED) {
        url = `/${config.ssoPath}/login?prompt=consent${scope ? `&scope=${scope}` : ''}`;
    } else {
        url = `/${config.ssoPath}/login${scope ? `?scope=${scope}` : ''}`;
    }
    setLoginIntention(LOGIN_INTENTION);
    window.location.href = addResumePageQueryParam(url, config.resumePage, window.location.href);
};

/**
 * @callback manualCallback
 * @param {string} [customScope]
 */
/**
 * @param {cfg} config
 * @returns manualCallback
 */
export const manualLogin = config => (customScope) => {
    const err = getSession();
    const sessionScope = getSessionScope();
    clearSessionStorage();
    setNoInteraction();
    clearLiveInteraction();
    manualLoginFor(config)(err, customScope, sessionScope);
};

/**
 *
 * @param {cfg} config
 * @returns {function(): void}
 */
export const newAccount = config => () => {
    const tempScope = getSessionScope();
    const scope = getScopesWith(tempScope);
    clearSessionStorage();
    setNoInteraction();
    clearLiveInteraction();
    window.location.href = addResumePageQueryParam(`/${config.ssoPath}/login?prompt=login&login_hint=registration${scope ? `&scope=${scope}` : ''}`, config.resumePage);
};

/**
 *
 * @param {cfg} config
 */
export const addScopePrompting = (config, getIdToken) => (customScope) => {
    getIdToken((idToken) => {
        const scope = getScopesWith(customScope);

        let loginUrl;
        if (idToken) {
            loginUrl = `/${config.ssoPath}/login?prompt=consent&scope=${scope}&id_token_hint=${idToken}`;
        } else {
            loginUrl = `/${config.ssoPath}/login?prompt=consent&scope=${scope}`;
        }

        clearSessionStorage();
        enableForceLoadUserData();
        window.location.href = addResumePageQueryParam(loginUrl, config.resumePage);
    });
};

const getNewChildLoginCodeIfNeeded = (config, geoLocation) => new Promise((resolve, reject) => {
        const code = getChildLoginCode();
        if (code && (new Date(code.expiration_time) > Date.now())) {
            resolve(code.login_code);
        } else {
            clearChildLoginCode();
            request(RequestMethod.POST, `/${config.ssoPath}/subprofileauth`, {'Content-Type': 'application/x-www-form-urlencoded'}, {geo_location: geoLocation || 'BE'}, true, true)
            .then((rawData) => {
                const data = JSON.parse(rawData);
                setChildLoginCode(data);
                resolve(data.login_code);
            }).catch((e) => {
                error('request error', e);
                reject(e);
            });
        }
    });

export const loginAsChild = config => async (geoLocation) => {
    await getNewChildLoginCodeIfNeeded(config, geoLocation)
    .then((loginCode) => {
        clearSessionStorage();
        setNoInteraction();
        clearLiveInteraction();
        setLoginIntention(LOGIN_INTENTION);
        window.location.href = `/${config.ssoPath}/login?login_code=${loginCode}`;
    })
    .catch((e) => {
        error('loginAsChild error', e);
        clearChildLoginCode();
    });
};
